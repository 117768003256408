@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.textbox {
  font-family: "Roboto";
  font-size: 16px;
  width: 100%;
  padding: 10px;
}

.footer {
  height: 60px;
  width: 100%;
  background-color: #111;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
  margin: 0;
  padding: 0;
}

.link {
  height: 25px;
  opacity: 0.88;
  text-decoration: none;
  display: flex;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.frog-img {
  max-width: 100%;
}

.text {
  font-family: "Roboto";
  margin-left: 25px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  align-self: center;
}

body {
  margin: 0;
}

.type-img {
  max-width: 100%;
  border: #111 solid;
  margin-bottom: 10px;
}
